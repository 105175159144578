import React from "react"
import { SiteMetadata } from "../components"
// import { Link } from "gatsby"

import "../styles/style.css"

const Update = () => {
  const triggerUpdate = () => {
    var xhr = new XMLHttpRequest()
    xhr.open(
      "POST",
      "https://api.netlify.com/build_hooks/600cff0f0590d2bf851bc9d4?trigger_title=triggered+by+website+sync+button",
      true
    )

    //Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")

    xhr.onreadystatechange = function () {
      // Call a function when the state changes.
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        // Request finished. Do processing here.
      }
    }
    xhr.send()
  }

  return (
    <div className="flex min-h-screen items-center">
      <SiteMetadata
        title="Refresh site data"
        description="Update menu items from Airbase"
      />
      <div className="container">
        <h3 className="text-2xl lg:text-4xl font-bold text-blue-700 dark:text-blue-700 tracking-tight">
          Update website content
        </h3>
        <p className="text-gray-500 dark:text-gray-500 font-medium text-base lg:text-lg pb-4 lg:pb-0 pt-1">
          After you click this button, it will take a few minutes to rebuild the
          website. You can check the status{" "}
          <a
            href="https://app.netlify.com/sites/sweeties-baked-goods/deploys"
            className="text-blue-500 dark:text-blue-300"
          >
            here
          </a>
          .
        </p>
        <input
          className="mt-8 rounded-lg text-center px-4 py-1 leading-loose text-lg font-medium transition-colors duration-200 focus:outline-none focus:shadow-outline bg-blue-700 dark:bg-blue-400 text-white dark:text-blue-900 cursor-pointer hover:bg-blue-500"
          type="submit"
          value="Refresh data from Airtable"
          onClick={(e) => triggerUpdate()}
        />
      </div>
    </div>
  )
}

export default Update
